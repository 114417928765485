<template>
  <div
    class="flex justify-content-end border-b-4 border-b-gray top-navbar__global-search"
    :class="isDark ? 'is-dark' : 'is-light'"
  >
    <span class="p-input-icon-left global-search-input">
      <i class="pi pi-search" />
      <InputText v-model="search" placeholder="Search ..." class="cursor-pointer" @click="visible = true" />
    </span>
  </div>

  <Dialog
    v-model:visible="visible"
    modal
    :pt="{
      mask: { style: 'backdrop-filter: blur(2px)' }
    }"
    closable
    dismissable-mask
    class="dialog__global-search"
  >
    <template #container="{}">
      <div
        class="flex px-3 py-3 gap-4 border-b-gray border-round-top-md w-[736px] dialog__global-search"
        :class="isDark ? 'is-dark' : 'is-light'"
      >
        <div class="flex relative w-8">
          <i class="absolute pi pi-search input-icon__global-search" />
          <i class="absolute pi pi-times cursor-pointer input-icon__global-search right-0" @click="clearSearch" />
          <InputText v-model="search" placeholder="Search ..." class="w-full px-5" autofocus />
        </div>
        <MultiSelect
          v-model="selectedGlobalSearchModels"
          :options="globalSearchModels"
          filter
          option-label="classname"
          placeholder="All"
          :max-selected-labels="3"
          class="w-4"
          :pt="{
            panel: { style: 'width: 230px' }
          }"
        />
      </div>
      <div>
        <Listbox
          id="global-search"
          :options="globalSearchResult"
          option-label="label"
          option-group-label="label"
          option-group-children="items"
          class="w-full border-round-bottom-md border-noround-top"
          empty-message="No search result found"
          :pt="{
            list: {
              style: 'max-height:350px'
            },
            itemGroup: {
              class: 'item-group__global-search'
            }
          }"
        >
          <template #optiongroup="slotProps">
            <div class="flex justify-content-between align-items-center text-400 font-normal border-t-gray pt-3">
              <div>{{ slotProps.option.moduleName }} >> {{ slotProps.option.className }}</div>
              <div class="p-buttonset">
                <Button
                  v-if="slotProps.option.pagination.prev_page_url"
                  size="small"
                  class="mr-3"
                  outlined
                  @click="paginate(slotProps.option.pagination, 0, slotProps.option)"
                >
                  « Previous
                </Button>
                <Button size="small" class="mr-3" outlined>{{ slotProps.option.pagination.current_page }}</Button>
                <Button
                  v-if="slotProps.option.pagination.next_page_url"
                  size="small"
                  type="button"
                  outlined
                  @click="
                    paginate(
                      slotProps.option.pagination,
                      slotProps.option.pagination.links.length - 1,
                      slotProps.option
                    )
                  "
                >
                  Next »
                </Button>
                <!-- <RouterLink to="/404" v-if="slotProps.option.pagination.last_page > 1">Show more</RouterLink> -->
              </div>
            </div>
          </template>
          <template #option="slotProps">
            <div class="flex align-items-center justify-content-between">
              <div
                v-for="(highlight, fieldName) in slotProps.option.highlights"
                :key="`highlight-${slotProps.option.id}-${highlight}`"
              >
                <div class="search-option" v-html="highlight"></div>
                <div class="text-400 text-sm">
                  {{ fieldName.toString().replaceAll('_', ' ').replaceAll('.', ' >> ') }}
                </div>
              </div>
              <div class="flex align-items-center">
                <p class="text-sm mr-3 text-500">Detail</p>
                <i class="pi pi-chevron-right text-xs" />
              </div>
            </div>
          </template>
        </Listbox>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts" generic="T extends ServiceInterface">
import { PropType, getCurrentInstance, onMounted, reactive, ref, watch } from 'vue';
import Listbox from 'primevue/listbox';
import { GlobalSearchModelSchema, MappedData, GlobalSearchResponseSchema } from '../../types/Global';
import { debounce } from '../../utilities/globalHelpers';
import useEmitter from '../../composables/useEmitter';
import { ServiceInterface, PaginationResult, GlobalSearchResult } from '../../types/Global';

const props = defineProps({
  searchService: {
    type: Object as PropType<ServiceInterface>,
    required: true
  }
});

const emitter = useEmitter();

const search = ref<string>('');
const visible = ref(false);
const globalSearchModels = reactive<GlobalSearchModelSchema[]>([]);
const selectedGlobalSearchModels = ref<GlobalSearchModelSchema[]>([]);
const globalSearchResult = reactive<MappedData[]>([]);
const globalSearchResponse = ref<GlobalSearchResponseSchema>();

const isDark = ref(false);

watch(
  search,
  debounce((newValue: string) => {
    if (newValue.length >= 3) {
      callSearch(newValue).catch(() => {});
    }
  })
);

watch(search, () => {
  globalSearchResult.splice(0, globalSearchResult.length);
});

const callSearch = async (newValue: string) => {
  const models = selectedGlobalSearchModels.value.map((model) => model.id);

  const { data } = await props.searchService.search(newValue, models);

  globalSearchResponse.value = data;

  Object.assign(globalSearchResult, getFormmatedSearchData(data));
};

const clearSearch = () => {
  search.value = '';
};

const pushToMappedData = (
  keyModule: string,
  keyClass: string,
  results: GlobalSearchResult[],
  pagination: PaginationResult
) => {
  return {
    moduleName: keyModule,
    className: keyClass,
    items: results,
    pagination: pagination
  };
};

const getFormmatedSearchData = (data: GlobalSearchResponseSchema) => {
  const mappedData: MappedData[] = [];

  for (const [keyModule, valueModule] of Object.entries(data?.results)) {
    for (const [keyClass, valueClass] of Object.entries(valueModule)) {
      valueClass.forEach((value) => {
        mappedData.push(pushToMappedData(keyModule, keyClass, value.results, value.pagination));
      });
    }
  }
  return mappedData.filter((data) => data.items.length > 0);
};

const paginate = async (paginate: PaginationResult, linkIndex: number, resultOptions: MappedData) => {
  const models = selectedGlobalSearchModels.value.map((model) => model.id);

  const fullUrl = paginate.links[linkIndex].url;

  const { data } = await props.searchService.search(search.value, models, fullUrl);

  const resultData = data.results?.[resultOptions.moduleName]?.[resultOptions.className]?.[0] || {};

  Object.assign(
    globalSearchResponse.value?.results?.[resultOptions.moduleName]?.[resultOptions.className] || {},
    resultData
  );
  const findPaginatedResult = (result: MappedData) => {
    return result.moduleName === resultOptions.moduleName && result.className === resultOptions.className;
  };

  Object.assign(globalSearchResult.find(findPaginatedResult)?.items || {}, resultData.results);
  Object.assign(globalSearchResult.find(findPaginatedResult)?.pagination || {}, resultData.pagination);
};

emitter!.on<'open-global-search'>('open-global-search', () => {
  visible.value = true;
});

onMounted(async () => {
  isDark.value = getCurrentInstance()?.appContext.config.globalProperties.$isDark as boolean;
  const { data } = await props.searchService.getModels();

  Object.assign(globalSearchModels, data);
});
</script>

<style scoped lang="scss">
i.input-icon__global-search {
  top: 50%;
  transform: translateY(-50%);
  padding: 0 8px;
}
.search-option :deep(em) {
  font-style: normal;
  font-weight: bold;
}

#global-search ::-webkit-scrollbar {
  overflow-y: scroll;
  scrollbar-color: transparent transparent;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#global-search ::-webkit-scrollbar {
  width: 6px;
}

#global-search ::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(180, 180, 180, 0.3);
}
#global-search ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #535353;
}
@media screen and (max-width: 967px) {
  .top-navbar__global-search {
    display: none !important;
  }
}
.w-\[230px\] {
  max-width: 230px;
}
:global(.dialog__global-search) {
  width: 736px;
}
.global-search-input {
  padding: 17px 32px 17px 0;
}
</style>
