import moment from 'moment';
import {
  ErrorResponseType,
  GeneralResponseError,
  LocalStorageUserType,
  tableConvertedParams,
  tableParams
} from '../types/Global';
import { AxiosError, AxiosResponse, AxiosResponseHeaders } from 'axios';
import { addressInterface } from '../types/Staff-Dashboard/tables/LocationsParams';
import { QualificationServicesItem } from '../types/Customer-Dashboard/Qualification';
import { DataTableFilterMeta } from 'primevue/datatable';
import { contractorAccess } from '../utilities/Permission';

export const convertHourFormat = (format: number, hour: string) => {
  if (format !== 12 && format !== 24) throw 'Invalid time format.';
  if (!hour) throw 'Invalid hour.';
  switch (format) {
    case 12:
      return moment(hour, 'hh:mm A').format('hh:mm A');
    case 24:
      return moment(hour, ['h:mm A']).format('HH:mm');
  }
};
export const statusClass = (status: number | undefined) => {
  return [
    {
      'p-tag': !status || status > 3,
      'p-tag p-tag-success': status === 1,
      'p-tag p-tag-danger': status === 2,
      'p-tag p-tag-warning': status === 3
    }
  ];
};
export const normalizeGetTableQuery = (
  query: tableParams,
  itemId?: number | string | null,
  itemType?: string | null
) => {
  const searchable_columnsSample = query.filters ? Object.keys(query.filters) : [];
  if (searchable_columnsSample[0] === 'global') searchable_columnsSample.splice(0, 1);
  const convertedQuery: tableConvertedParams = {
    searchable_columns: searchable_columnsSample,
    dt_params: {
      page: Number(query.page),
      rows: Number(query.rows),
      filters: query.filters ? (query.filters as DataTableFilterMeta) : {}
    }
  };
  if (typeof itemId !== 'undefined' && typeof itemType !== 'undefined') {
    if (!convertedQuery.searchable_columns.includes(itemType as string)) {
      convertedQuery.searchable_columns.push(itemType as string);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    convertedQuery.dt_params.filters[itemType] = {
      operator: 'and',
      constraints: [{ value: itemId, matchMode: 'equals' }]
    };
  }
  if (query.sortField) (convertedQuery.dt_params.sortField as string | number) = query.sortField;
  if (query.sortOrder) convertedQuery.dt_params.sortOrder = Number(query.sortOrder);
  return {
    dt_params: JSON.stringify(convertedQuery.dt_params),
    searchable_columns: JSON.stringify(convertedQuery.searchable_columns)
  };
};

export const globalAxiosErrorHandler = (error: AxiosError<ErrorResponseType<GeneralResponseError>>) => {
  const ErrorObject = {
    summary: '',
    detail: ''
  };
  if (!error.response || !error.response.data || !error.response.data.errors) {
    ErrorObject.summary = 'Error';
    ErrorObject.detail = 'This is an unexpected error, please notify IT';
  } else {
    ErrorObject.summary = 'Error: ' + error.response.data.message;
    for (const property in error.response.data.errors) {
      ErrorObject.detail += `${capitalize(property)}: ${
        error.response.data.errors[property as keyof GeneralResponseError]
      } \n`;
    }
  }
  return ErrorObject;
};
export const capitalize = (text: string) => {
  text = text.toLowerCase();
  return text
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
export const generateAddress = (address: addressInterface) => {
  if (address.address && address.suite) {
    return address.address + ', ' + address.suite;
  } else if (address.address && !address.suite) {
    return address.address;
  } else if (!address.address && !address.suite) {
    return '';
  }
};
export const generateFullAddress = (address: addressInterface) => {
  let finalAddress = '';
  if (address.address) finalAddress += address.address + ', ';
  if (address.suite) finalAddress += address.suite + ', ';
  if (address.city) finalAddress += address.city + ', ';
  if (address.state) finalAddress += address.state + ', ';
  if (address.postal_code) finalAddress += address.postal_code + ', ';
  if (address.country) finalAddress += address.country.iso2;
  return finalAddress;
};
export const convertIsoDateToString = (isoDate: string, format?: string) => {
  if (!format) format = 'YYYY-MM-DD';
  return moment(isoDate).format(format);
};
export const convertIsoDateToStringFull = (isoDate: string, format?: string) => {
  if (!format) format = 'YYYY-MM-DD, hh:mm A';
  return moment(isoDate).format(format);
};
export const convertIsoDateFromNow = (isoDate: string) => {
  return moment(isoDate).fromNow();
};
export const downloadFileFromRequest = (headers: AxiosResponseHeaders, file: File, format: string) => {
  switch (format) {
    case 'csv':
      format = 'text/csv';
      break;
    case 'excel':
      format = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    default:
      format = '';
      break;
  }
  if (!format || format === '') throw new Error('Invalid file type');
  /* eslint-disable */
  const filename = headers['content-disposition'].split('filename=')[1].split('.')[0];
  const extension = headers['content-disposition'].split('.')[1].split(';')[0];
  const url = window.URL.createObjectURL(new Blob([file], { type: format }));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename + extension);
  document.body.appendChild(link);
  link.click();
  /* eslint-disable */
};
export const getFileNameFromUrl = (url: string) => {
  return url.substring(url.lastIndexOf('/') + 1);
};
export const getFileExtensionFromFileName = (fileName: string) => {
  return fileName.substring(fileName.lastIndexOf('.') + 1);
};
export const fileTypeIcon = (fileType: string) => {
  switch (fileType) {
    case 'pdf':
      return 'file-pdf';
    case 'jpg':
    case 'png':
    case 'svg':
      return 'file-image';
    case 'xls':
    case 'xlsx':
      return 'file-excel';
    case 'doc':
    case 'docx':
      return 'file-word';
    case 'txt':
      return 'file-lines';
    case 'json':
    case 'yaml':
      return 'file-code';
    case 'zip':
    case 'rar':
      return 'file-zip';
  }
  return 'memo';
};
export const isBlob = (response: AxiosResponse) => {
  const jsonMimeType = 'application/json';
  const dataType = (response.data as { type: string }).type;
  return response.data instanceof Blob && dataType !== jsonMimeType;
};
export const dayConvertor = (day: number): string => {
  switch (day) {
    case 6:
      return 'Saturday';
    case 7:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
  }
  return '';
};
export const convertServiceTypes = (item: QualificationServicesItem[]): string => {
  const converted: string[] = [];
  if (!item || !item.length) return '-';
  item.forEach((innerItem: QualificationServicesItem) => {
    if (innerItem.service_product && innerItem.service_product.name) converted.push(innerItem.service_product.name);
  });
  return converted.toString().replaceAll(',', ' - ');
};

export const copyLinkToClipboard = (linkElement: HTMLAnchorElement): void => {
  if (!navigator.clipboard) {
    const textArea: HTMLTextAreaElement = document.createElement('textarea');
    textArea.value = linkElement.href;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    alert('Link copied to clipboard');
  } else {
    void navigator.clipboard.writeText(linkElement.href).then((): void => {
      alert('Link copied to clipboard');
    });
  }
};

export const checkUserPermission = (permission: number) => {
  if (!permission) throw 'Permission is required.';
  if (!localStorage.getItem('user')) throw 'No user data found.';
  const user = JSON.parse(localStorage.getItem('user') as string) as LocalStorageUserType;
  const userPermissions = user.permissions ?? [];
  return userPermissions.includes(permission);
};

export const isContractor = () => {
  if (!localStorage.getItem('user')) throw 'No user data found.';
  const user = JSON.parse(localStorage.getItem('user') as string) as LocalStorageUserType;
  const userPermissions = user.permissions ?? [];
  return userPermissions.includes(contractorAccess);
};
export const debounce = <T extends unknown[]>(
  callback: (...args: T) => void,
  timeout = 500
): ((...args: T) => void) => {
  let timer: ReturnType<typeof setTimeout>;

  return (...args: T) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      callback.apply(this, args);
    }, timeout);
  };
};
export const currencyFormat = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });
  return formatter.format(value);
};
